import cn from 'classnames'

type Props = {
  type: string
  children: JSX.Element
}

export default function Callout({ type = 'note', children }: Props) {
  return (
    <div
      className={cn('mb-3 border p-3', {
        'border-green-600 dark:border-green-500': type === 'tip',
        'border-orange-500': type === 'warning',
        'border-blue-600 dark:border-blue-500': type === 'note',
        'border-purple-600 dark:border-purple-500': type === 'update'
      })}
    >
      <p>
        <span
          className={cn({
            'bg-green-600 px-sm text-white dark:bg-green-500 dark:text-black': type === 'tip',
            'bg-orange-500 px-sm text-white dark:text-black': type === 'warning',
            'bg-blue-600 px-sm text-white dark:bg-blue-500 dark:text-black': type === 'note',
            'bg-purple-600 px-sm text-white dark:bg-purple-500 dark:text-black': type === 'update'
          })}
        >
          {type.charAt(0).toUpperCase() + type.substring(1)}
        </span>
      </p>
      {children}
    </div>
  )
}

Callout.scheme = {
  render: 'Callout',
  attributes: {
    type: {
      type: String,
      default: 'note',
      matches: ['tip', 'update', 'note', 'warning']
    }
  }
}
